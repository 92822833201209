@use "variables"as *;
@use "map"as *;

// shimmer
@mixin shimmer($w: null, $h: null, $r: 50vmax) {
  width: $w;
  height: $h;
  display: block;
  font-size: 0;
  -webkit-animation: loading 1s linear 0s infinite normal forwards;
  animation: loading 1s linear 0s infinite normal forwards;
  background-color: var(--shimmer-light-hsl-color);
  background-image: linear-gradient(to right,
      var(--shimmer-light-hsl-color) 0%,
      var(--shimmer-dark-hsl-color) 20%,
      var(--shimmer-light-hsl-color) 40%,
      var(--shimmer-light-hsl-color) 100%);
  background-repeat: no-repeat;
  background-size: 80rem 100%;
  @include border-radius($r, hidden);
  > * {
    font-size: 0;
  }
}
@mixin shadow($box-shadow...) {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}
@mixin placeholder($color) {
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    color: $color;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $color;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $color;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: $color;
  }
}
@mixin flex-config($display,
  $flex-direction: null,
  $justify-content: null,
  $align-items: null) {
  display: $display;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}
@mixin linear-gradient($gradientValues...) {
  background: -webkit-linear-gradient($gradientValues);
  background: -moz-linear-gradient($gradientValues);
  background: -ms-linear-gradient($gradientValues);
  background: -o-linear-gradient($gradientValues);
  background: linear-gradient($gradientValues);
}
@mixin transform($value) {
  -webkit-transform: $value;
  -moz-transform: $value;
  -ms-transform: $value;
  transform: $value;
}
@mixin translate($x, $y) {
  -webkit-transform: translate($x, $y);
  -moz-transform: translate($x, $y);
  -ms-transform: translate($x, $y);
  -o-transform: translate($x, $y);
  transform: translate($x, $y);
}
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}
@mixin transition($transition...) {
  -moz-transition: $transition;
  -o-transition: $transition;
  -webkit-transition: $transition;
  transition: $transition;
}
@mixin position-combo($combo: null, $x: null, $y: null, $position: absolute) {
  position: $position;
  @if $combo=="tl" {
    top: 0;
    left: 0;
  }
  @else if $combo=="tr" {
    top: 0;
    right: 0;
  }
  @else if $combo=="bl" {
    bottom: 0;
    left: 0;
  }
  @else if $combo=="br" {
    bottom: 0;
    right: 0;
  }
  @else if $combo=="inset" {
    inset: 0;
  }
  @else if $combo=="center" {
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
  }
  @else if $combo=="y-center" {
    top: 50%;
    right: $x;
    left: $y;
    @include translate(0, -50%);
  }
  @else if $combo=="x-center" {
    left: 50%;
    top: $x;
    bottom: $y;
    @include translate(-50%, 0);
  }
}
//how to use
// @include position-combo(tl);
// @include position-combo(br);
// @include position-combo(x-center,null,4rem);
@mixin position($top: null, $right: null, $bottom: null, $left: null, $position: absolute) {
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
  position: $position;
}
@mixin border-radius($value, $overflow: visible) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  -ms-border-radius: $value;
  border-radius: $value;
  overflow: $overflow;
}
@mixin card-count($count, $gap: 0rem, $flex-wrap: wrap) {
  gap: $gap;
  flex-wrap: $flex-wrap;
  @include flex-config(flex);
  & > * {
    width: calc(100% / $count - (($count - 1) * $gap / $count));
    flex-shrink: 0;
  }
}
// how to use
// @include card-count(1);
// @include card-count(2,var(--full-space));
// @include card-count(2.5,var(--full-space),nowrap);
@mixin card-count-new($count, $gap: null, $wrap: false, $update: false) {
	@if $update ==false {
		@include flex-config(flex);
		@if $wrap==true {
			flex-wrap: wrap;
		}
		@else {
			overflow-x: auto;
		}
		& > * {
			flex-shrink: 0;
		}
	}
	$availableWidth: calc(100% - (ceil($count) - 1) * $gap);
	@if $gap !=null {
		gap: $gap;
	}
	& > * {
		width: calc($availableWidth / $count);
	}
}
// how to use
// @include card-count-new(2, var(--space-4));
// @include card-count-new(2.5, var(--space-4));
// @include card-count-new(3, var(--space-4), true); For wrapping
// @include card-count-new(4, var(--space-6), false, true); // For only updating card-count in media query.
@mixin font($font-size, $line-height: null, $font-family: null) {
  font-size: calc($font-size * 0.1rem);
  line-height: calc(($font-size * 0.1rem) * 1.5);
  font-family: $font-family;
}
// @include font(20,inherit,$font-primary-bold);
// @include font(20,20,$font-primary-bold);
// @include font(20,30);
// @include font(20);
// @include font(20,null,$font-primary-bold);
@mixin bg($color, $opacity: 10) {
  background-color: hsl(var(--hsl-#{$color}) / #{calc($opacity / 10)});
}
@mixin color($color, $opacity: 10) {
  color: hsl(var(--hsl-#{$color}) / #{calc($opacity / 10)});
}
@mixin dropdown($status, $animation-delay: 300ms) {
  @if $status==close {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    @include transition(clip-path $animation-delay linear);
  }
  @else if $status==open {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin dropup($status, $animation-delay: 300ms) {
  @if $status==close {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    @include transition(clip-path $animation-delay linear);
  }
  @else if $status==open {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin slide-left($status, $animation-delay: 300ms) {
  @if $status==close {
    clip-path: polygon(100% 0, 100% 0%, 100% 100%, 100% 100%);
    @include transition(clip-path $animation-delay linear);
  }
  @else if $status==open {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
}
@mixin fade-in($status, $animation-delay: 300ms) {
  @if $status==close {
    opacity: 0;
    pointer-events: none;
    @include transition(opacity $animation-delay linear);
  }
  @else if $status==open {
    pointer-events: inherit;
    opacity: 1;
  }
}
@mixin custom-scroll {
  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }
  &::-webkit-scrollbar-track {
    background: hsl(var(--hsl-c-black) / 0.1);
    border-radius: var(--half-radius);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: var(--half-radius);
    background: hsl(var(--hsl-c-black)/0.3);
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: var(--half-radius);
    background: hsl(var(--hsl-c-black)/0.7);
  }
}
@mixin truncate-vertical($line-number,
  $font-size,
  $line-height: null,
  $font-family: null) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: $line-number;
  font-family: $font-family;
  font-size: $font-size * 0.1rem;
  @if $line-height !=null {
    line-height: calc($line-height * .1rem);
  }
  @else if $line-height==null {
    $line-height: $font-size * $default-line-height;
  }
  height: calc($line-number * $line-height * 0.1rem);
}
// @include truncate-vertical(3,16,22,$font-regular);
// @include truncate-vertical(3,16,null,$font-regular);
// @include truncate-vertical(3,16,null,null);
@mixin truncate-text($line-number, $height: auto) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: $line-number;
  @if $height !=auto {
    height: $height * 0.1rem;
  }
}
// how to use
// @include truncate-text(line-number, height);
// @include truncate-text(3, 40);
// @include truncate-text(4, 20);
// @include truncate-text(2);
@mixin grid($count, $gap: var(--full-space)) {
  display: grid;
  grid-template-columns: $count;
  gap: $gap;
}
@mixin btn($border: none, $bg: $bg, $txt-color: $txt-color) {
  color: $txt-color;
  background: $bg;
  border-radius: var(--two-space);
  padding: var(--half-space) var(--full-space);
  @if border==none {
    border: none;
  }
  @else {
    border: $border;
  }
}
@mixin icon($icon, $size: 14, $line-height: null) {
  content: '#{$icon}';
  // color: $color;
  @include font($size, $line-height, $font-icon)
}
@mixin border($width: 1, $color: null, $opacity: 10, $direction: all, $style: solid) {
  $opacity-decimal: calc($opacity / 10);
  $color-hsla: hsl(var(--hsl-#{$color}) / $opacity-decimal);
  @if $direction==all {
    border: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction==left {
    border-left: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction==right {
    border-right: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction==top {
    border-top: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction==bottom {
    border-bottom: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction==y {
    border-block: calc($width * 0.1rem) $style $color-hsla;
  }
  @else if $direction==x {
    border-inline: calc($width * 0.1rem) $style $color-hsla;
  }
  @else {
    @error "Invalid direction #{$direction}, must be one of: all, left, right, top, bottom.";
  }
}
// how to use
// @include border(1,c-purple);
// @include border(1,c-purple,3,left,dotted);
// @include border(border-width,border-color,border-color-opacity,border-position,border-style);
// ***** color name is got through map name so use same name as used in map
@mixin event-card($event, $event-key) {
  .card-item,
  .event-item {
    position: relative;
    // background:url(/static-assets/images/events/event-bg-pattern.png?v=#{$image-version}) no-repeat center center;
    // background-size: 100% 100%;
    height: 18rem;
    @include border-radius(var(--half-radius));
    @include transition(all 1s);
    @extend %flex-column;
    @extend %m-b-two-space;
    // @extend %w-100;
    // @extend %p-full;
    .event-thumb {
      position: relative;
      &::after {
        content: '';
        border-radius: var(--half-radius);
        background: linear-gradient(246deg, hsl(var(--hsl-c-institutional-primary) / .6) 4.99%, var(--c-institutional-secondary) 100.98%);
        @include position-combo(inset);
        @include border-radius(var(--half-radius));
      }
      img {
        filter: grayscale(1);
      }
    }
    // &:hover {
    //   background-size: 110% 110%;
    // }
    // &::after {
    //   content: '';
    //   // opacity: 0.7;
    //   @include linear-gradient(120deg,
    //     var(--event-#{$event-key}-primary) 60%,
    //     var(--event-#{$event-key}-secondary) 80%);
    //   @include position-combo(inset);
    //   @include border-radius(var(--half-radius));
    // }
    .event-badge {
      width: 4.2rem;
      height: 4.2rem;
      bottom: var(--full-space-negative);
      z-index: var(--zindex1);
      background:url(/static-assets/images/cssimages/svg/events/corporate.svg?v=#{$image-version}) no-repeat;
      @extend %ratio-1-1;
      @extend %position-h-center;
      @extend %circle-radius;
      @extend %font-zero;
    }
    &.#{$event} .event-badge {
      background-image: url(/static-assets/images/cssimages/svg/events/#{$event}.svg?v=#{$image-version});
    }
    @if $event =='ranking-series' {
      &.ranking-series .event-thumb::after {
        background: linear-gradient(126.85deg, hsl(var(--hsl-c-rank-series-primary) / .7) 1.88%, hsl(var(--hsl-c-rank-series-secondary) / .7) 89.64%);
      }
    }
    @if $event =='world-cup' {
      &.world-cup .event-thumb::after {
        background: linear-gradient(246deg, hsl(var(--hsl-c-world-cup-card-1) / .6) 4.99%, hsl(var(--hsl-c-world-cup-card-2) / .7) 100.98%);
      }
    }
    @if $event =='world-championships' {
      &.world-championships .event-thumb::after {
        background: linear-gradient(246deg, hsl(var(--hsl-c-w-champ-primary) / .6) 4.99%, hsl(var(--hsl-c-w-champ-secondary) / .7) 100.98%);
      }
    }
    @if $event =='continental-championships' {
      &.continental-championships .event-thumb::after {
        background: linear-gradient(179.4deg, hsl(var(--hsl-c-continental-card-1) / .6) -13.8%, hsl(var(--hsl-c-continental-card-2) / .8) 99.55%);
      }
    }
    @if $event =='beach-wrestling-world-series' {
      &.beach-wrestling-world-series .event-thumb::after {
        background: linear-gradient(235.45deg, hsl(var(--hsl-c-beach-card-1) / .6) -9.44%, hsl(var(--hsl-c-beach-card-2) / .6) 84%);
      }
    }
    @if $event =='olympic-qualifying-tournament' {
      &.olympic-qualifying-tournament .event-thumb::after {
        background: linear-gradient(235.45deg, var(--c-light-grey-2) -9.44%, hsl(var(--hsl-c-olympic-qualifier-primary) / .6) 84%);
      }
    }
    @if $event =='olympic-games' {
      &.olympic-games .event-thumb::after {
        background: linear-gradient(235.45deg, var(--c-light-grey-2) -9.44%, hsl(var(--hsl-c-olympic-qualifier-primary) / .6) 84%);
      }
    }
  }
}
@mixin event-strip($event, $event-key) {
  .event-strip {
    background-color: var(--c-blue);
    &.#{$event} {
      background: var(--event-#{$event-key}-secondary);
    }
  }
}
@mixin fav-event($event, $event-key) {
  .card-item {
    position: relative;
    isolation: isolate;
    background:url(/static-assets/images/cssimages/events/fav-event-bg.png?v=#{$image-version}) no-repeat center center;
    background-size: 100% 100%;
    @include border-radius(1rem, hidden);
    @include transition(all 1s);
    @extend %flex-column;
    @extend %m-b-two-space;
    @extend %w-100;
    &.#{$event} {
      background:url('/static-assets/images/events/#{$event-key}.svg?v=#{$image-version}') no-repeat center center;
      background-size: 100%;
      &:hover {
        &::after {
          content: '';
          z-index: var(--zindex-pattern);
          @include position-combo(inset);
          @include border-radius(var(--half-radius));
          @include linear-gradient(162deg, hsl(var(--hsl-c-white)/.1), hsl(var(--hsl-c-white)/0.1));
        }
      }
      // &:hover {
      //   &::after {
      //     content: '';
      //     z-index: var(--zindex-pattern);
      //     @include position-combo(inset);
      //     @include border-radius(var(--half-radius));
      //     @include linear-gradient(162deg, hsl(var(--hsl-c-white)/.1), hsl(var(--hsl-c-white)/0.1));
      //   }
      // }
    }
    // &::after {
    //   content: '';
    //   // opacity: 0.7;
    //   z-index: var(--zindex-pattern);
    //   @include position-combo(inset);
    //   @include border-radius(var(--half-radius));
    // }
    // &:before {
    //   content: '';
    //   width: 100%;
    //   height: 100%;
    //   z-index: var(--zindex1);
    //   background:url(/static-assets/images/cssimages/events/fav-event-bg.png?v=#{$image-version}) no-repeat;
    //   background-size: 100% 100%;
    //   @include position-combo(inset);
    // }
  }
}
@mixin event-title($event, $event-key) {
  .#{$event} {
    .sub-title {
      color: var(--event-#{$event-key}-primary);
      @extend %flex;
      &::before {
        content: '';
        width: 2.5rem;
        height: 2.5rem;
        background: url(/static-assets/images/cssimages/svg/events/#{$event}.svg?v=#{$image-version}) no-repeat;
        background-size: contain;
        @extend %ratio-1-1;
        @extend %flex-c-c;
        @extend %m-r-half;
      }
    }
  }
}
@mixin input($type: $type) {
  @if (($type==text) or ($type==email) or ($type==password) or ($type==select)) {
    input[type=#{$type}] {
      background: transparent;
      width: 100%;
      height: 100%;
      padding-inline: var(--full-space);
      @include border(0, null, 0, top);
      @include border(0, null, 0, x);
      @include border(1, c-white, 10, bottom);
      @include font(18, 28, $font-pr);
      @include color(c-white, 6);
      &:active,
      &:focus,
      &:focus-visible {
        border-color: var(--c-orange);
      }
    }
  }
  @if $type==email {
    .email-field {
      position: relative;
      &::before {
        @include icon('\e80d');
        @include font(20);
        @include position(null, null, var(--quarter-space), var(--half-space));
        @include color(c-blue, 6);
      }
      input {
        padding-left: var(--three-space);
      }
    }
  }
  @if $type==select {
    .form-control {
      background: transparent;
      width: 100%;
      // height: 100%;
      padding-inline: var(--full-space);
      @include border(0, null, 0, top);
      @include border(0, null, 0, x);
      @include border(1, c-white, 10, bottom);
      @include font(18, 28, $font-pr);
      @include color(c-white, 10);
      &:focus-visible {
        outline: none;
      }
      option {
        @include color(c-black, 10);
      }
    }
    .control-label {
      text-align: left;
      display: block;
      margin-bottom: var(--half-space);
      @include color(c-white, 6);
      @include font(12, null, $font-pr);
    }
  }
  @if $type=='radio' {
    .radio-box {
      height: 2rem;
      width: 2rem;
      display: block;
      position: relative;
      cursor: pointer;
      // margin-bottom: var(--full-space);
      input {
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: var(--zindex1);
        @include flex-config(flex);
        @include position-combo(tl);
        &:checked {
          + .checkmark {
            @include bg(c-white, 10);
            @include border(1, c-orange, 10, all);
            &:after {
              display: block;
            }
          }
        }
        &:disabled {
          + .checkmark {
            opacity: 0.6;
          }
        }
      }
      .checkmark {
        &:after {
          width: 1rem;
          height: 1rem;
          @include position-combo(center);
          @include bg(c-orange, 10);
          @extend %ratio-1-1;
          @extend %circle-radius;
        }
      }
    }
    .checkmark {
      height: 2rem;
      width: 2rem;
      @include bg(c-white, 1);
      @include position-combo(tl);
      @include border(1, c-white, 6, all);
      @extend %circle-radius;
      @extend %ratio-1-1;
      &:after {
        content: "";
        position: absolute;
        display: none;
      }
    }
  }
  @if $type=='checkbox' {
    .input-checkbox {
      display: block;
      position: relative;
      cursor: pointer;
      padding-left: var(--two-space);
      margin-bottom: var(--full-space);
      .text {
        @extend %c-white-8;
        @extend %text-left;
      }
      input {
        opacity: 0;
        cursor: pointer;
        z-index: var(--zindex-input);
        @include position-combo(tl);
        &:checked {
          + .checkmark {
            @include bg(c-orange, 10);
            @include border(1, c-orange, 10, all);
            &:after {
              display: block;
            }
          }
        }
        &:disabled {
          + .checkmark {
            opacity: 0.6;
          }
        }
      }
      .checkmark {
        &:after {
          // height: 0.9rem;
          @include position-combo(center);
          @extend %ratio-1-1;
          @extend %circle-radius;
        }
      }
      .checkmark {
        height: 2rem;
        width: 2rem;
        @include bg(c-black, 1);
        @include position-combo(tl);
        @include border(1, c-white, 6, all);
        @include border-radius(0.3rem);
        @extend %ratio-1-1;
        &:after {
          content: "\e805";
          position: absolute;
          display: none;
          font: 1.4rem/1 $font-icon;
          @include color(c-white, 10);
          // @include icon('');
        }
      }
    }
  }
}
@mixin white-bg() {
  .filter-section {
    .filter {
      &-search {
        width: auto;
        @include position(1rem, 0);
      }
      &-body {
        width: calc(100% - 20rem);
      }
      &-result-item {
        label {
          @include bg(c-blue);
          @include color(c-white);
        }
      }
    }
    .selected-label {
      border-color: hsl(var(--hsl-c-blue)/0.3);
      @include color(c-blue);
      &::after {
        @include color(c-black);
      }
    }
    .sub-title {
      @include color(c-blue);
    }
    .list-item-option {
      .list-item-text {
        @include color(c-blue);
      }
    }
    .toggle-text {
      &::before {
        color: var(--c-blue);
      }
    }
    .secondary {
      .tab-name {
        border-color: var(--c-grey);
        &.active {
          @include bg(c-blue);
          .tab-text {
            @include color(c-white);
          }
        }
      }
      .tab-text {
        color: var(--c-blue-1);
      }
    }
    .selected-label {
      border-color: var(--c-grey);
      @include color(c-blue);
    }
    .sub-title {
      @include color(c-blue, 6);
    }
    .select-box-wrap {
      box-shadow: 0 0.4rem 1.6rem hsl(var(--hsl-c-black)/0.1);
      padding-inline: var(--half-space);
      @include border-radius(var(--half-radius));
      @include bg(c-white);
      .list-item {
        border-color: hsl(var(--hsl-c-blue)/0.2);
        a,
        button {
          @include color(c-blue);
        }
      }
      .list-item-option {
        &:after {
          border-color: var(--c-orange);
        }
      }
    }
    .select-box-footer {
      .btn-link {
        @include color(c-blue);
      }
    }
    .toggle-body {
      @include bg(c-black, 5);
    }
  }
}
@mixin overlay() {
  // // overlay style 
  .article {
    &-thumbnail {
      @extend %w-100;
    }
    &-item {
      position: relative;
      overflow: hidden;
      @extend %flex-column-n-n;
      @extend %c-black-bg-2;
      @extend %half-radius;
      &::after {
        content: '';
        z-index: var(--zindex1);
        position: absolute;
        inset: 0;
        pointer-events: none;
        // @include linear-gradient(180deg, hsl(var(--hsl-c-black)) 0%, hsl(var(--hsl-c-blue)/0.6) 41.67%, hsl(var(--hsl-c-blue)/0.9) 100%);
        @include linear-gradient(180deg, rgba(4, 0, 63, 0.00) 0%, var(--c-blue) 100%);
      }
    }
    &-wrap {
      @extend %flex-column-n-n;
    }
    &-thumbnail {
      width: 100%;
    }
    &-title {
      // @extend %font-16-pb;
      @include truncate-vertical(2, 16);
      @extend %m-t-zero;
      @extend %m-b-half;
    }
    &-content {
      flex-wrap: wrap;
      z-index: var(--overlay-image);
      @extend %position-b-l;
      @extend %w-100;
      @extend %p-full;
      @extend %flex-n-n;
      a {
        @extend %w-100;
      }
    }
    &-info {
      order: unset;
    }
    &-meta {
      .meta-date {
        @extend %p-zero;
        @extend %m-zero;
        &::before {
          content: unset;
        }
      }
    }
  }
}
@mixin listing-card($type: card, $width: 16rem) {
  @if $type==rhs {
    .article {
      &-wrap {
        flex-direction: row;
      }
      &-thumbnail {
        width: $width;
        &::before {
          content: unset;
        }
      }
      &-content {
        width: calc(100%);
        position: unset;
        color: var(--c-white);
      }
    }
  }
  @if $type==overlay {
    .article {
      &-content {
        width: 100%;
        color: var(--white);
        @include position-combo(bl);
      }
      &-thumbnail {
        width: 100%;
        @include overlay();
      }
    }
  }
  @if $type==card {
    .article {
      &-thumbnail {
        width: 100%;
        &::before {
          content: unset;
        }
      }
      &-wrap {
        flex-direction: column;
      }
      &-content {
        width: 100%;
        position: relative;
        color: var(--c-white);
      }
    }
  }
}
@mixin shimmer-ads($width: null, $height: null, $radius: var(--half-space)) {
  width: $width;
  height: $height;
  display: block;
  font-size: 0;
  background: hsla(var(--hsl-c-shimmer-light)/1);
  background-repeat: no-repeat;
  border-radius: $radius;
  overflow: hidden;
  position: relative;
  &::after {
    content: "";
    background-image: linear-gradient(-45deg,
        hsla(var(--hsl-c-shimmer-light)/1) 0%,
        hsla(var(--hsl-c-shimmer-light)/1) 40%,
        hsla(var(--hsl-c-shimmer-dark)/1) 50%,
        hsla(var(--hsl-c-shimmer-light)/1) 60%,
        hsla(var(--hsl-c-shimmer-light)/1) 100%);
    position: absolute;
    inset: -150%;
    transform: translateX(-100%);
    -webkit-animation: placeholderShimmer 2s linear 0s infinite normal forwards;
    animation: placeholderShimmer 2s linear 0s infinite normal forwards;
  }
  * {
    font-size: 0;
  }
}
@mixin left-separator() {
  position: relative;
  line-height: 1;
  &:not(:first-child)::before {
    content: "";
    width: .1rem;
    height: 100%;
    background: hsl(var(--hsl-c-black) / .2);
    @include position(0, null, null, var(--full-space-negative));
  }
}
@media (min-width:$small-mobile-max-width) {}