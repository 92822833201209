@use "variables" as *;
[class^="icon-"] {
	font-size: 0;
	font-style: normal;
	color: var(--c-white);
}
[class^="icon-"]::before {
	font-family: $font-icon;
	font-size: 2rem;
	color: var(--c-white);
	font-style: normal;
	font-weight: normal;
	speak: never;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.icon-arrow-left:before { content: '\e800'; } /* '' */
.icon-arrow-right:before { content: '\e801'; } /* '' */
.icon-arrow-up:before { content: '\e802'; } /* '' */
.icon-big-arrow:before { content: '\e803'; } /* '' */
.icon-close:before { content: '\e804'; } /* '' */
.icon-done:before { content: '\e805'; } /* '' */
.icon-arrow-down:before { content: '\e806'; } /* '' */
.icon-edit_square:before { content: '\e807'; } /* '' */
.icon-favorite:before { content: '\e808'; } /* '' */
.icon-file_download:before { content: '\e809'; } /* '' */
.icon-file_download_done:before { content: '\e80a'; } /* '' */
.icon-filter:before { content: '\e80b'; } /* '' */
.icon-home:before { content: '\e80c'; } /* '' */
.icon-mail:before { content: '\e80d'; } /* '' */
.icon-map:before { content: '\e80e'; } /* '' */
.icon-menu:before { content: '\e80f'; } /* '' */
.icon-notify:before { content: '\e810'; } /* '' */
.icon-person:before { content: '\e811'; } /* '' */
.icon-pin_drop:before { content: '\e812'; } /* '' */
.icon-play_arrow:before { content: '\e813'; } /* '' */
.icon-play_circle:before { content: '\e814'; } /* '' */
.icon-search:before { content: '\e815'; } /* '' */
.icon-share:before { content: '\e816'; } /* '' */
.icon-thumb_up:before { content: '\e817'; } /* '' */
.icon-visibility:before { content: '\e818'; } /* '' */
.icon-add:before { content: '\e819'; } /* '' */
.icon-add_card:before { content: '\e81a'; } /* '' */
.icon-add_circle:before { content: '\e81b'; } /* '' */
.icon-arrow_right_alt:before { content: '\e81c'; } /* '' */
.icon-play-circled2:before { content: '\e81d'; } /* '' */
.icon-visibility_off:before { content: '\e81e'; } /* '' */
.icon-alarm:before { content: '\e820'; } /* '' */
.icon-help:before { content: '\e821'; } /* '' */
.icon-videocam:before { content: '\e822'; } /* '' */
.icon-switch:before { content: '\e824'; } /* '' */
.icon-zoom:before { content: '\e829'; } /* '' */
.icon-setting:before { content: '\e851'; } /* '' */
.icon-copylink:before { content: '\e853'; } /* '' */
.icon-facebook:before { content: '\e854'; } /* '' */
.icon-whatsapp:before { content: '\e855'; } /* '' */
.icon-minus-circle:before { content: '\e85a'; } /* '' */
.icon-instagram:before { content: '\e85e'; } /* '' */
.icon-tiktok:before { content: '\e85f'; } /* '' */
.icon-twitter:before { content: '\e861'; } /* '' */
.icon-star:before { content: '\e948'; } /* '' */
.icon-telephone:before { content: '\e85d'; } /* '' */
.icon-linkedin:before { content: '\e862'; } /* '' */
.icon-youtube:before { content: '\e864'; } /* '' */