@use "variables" as *;
@use "mixins" as *;
@use "map" as *;
@use "animation" as *;
// font sizes start
@for $font-size from 1 through 60 {
  %font-#{$font-size}-pr {
    font: #{calc($font-size * 0.1rem)}/$default-line-height $font-pr;
  }
  %font-#{$font-size}-pm {
    font: #{calc($font-size * 0.1rem)}/$default-line-height $font-pm;
  }
  %font-#{$font-size}-psb {
    font: #{calc($font-size * 0.1rem)}/$default-line-height $font-psb;
  }
  %font-#{$font-size}-pb {
    font: #{calc($font-size * 0.1rem)}/$default-line-height $font-pb;
  }
  %font-#{$font-size}-sb {
    font: #{calc($font-size * 0.1rem)}/$default-line-height $font-sb;
  }
  %font-#{$font-size} {
    font-size: #{calc($font-size * 0.1rem)};
  }
}
%font-zero {
  font-size: 0;
}
// font sizes end
@each $color-key,
$color-value in $colors {
  @for $i from 1 through 10 {
    %#{$color-key}-#{$i} {
      color: hsl(var(--hsl-#{$color-key}) / #{calc($i / 10)});
    }
    %#{$color-key}-bg-#{$i} {
      background-color: hsl(var(--hsl-#{$color-key}) / #{calc($i / 10)});
    }
  }
}
// you can select change the value from 1 to 10
// 1 means 0.1 opacity , 10 means no opacity solidcolor
// @extend %button-primary-1;
// @extend %button-primary-10;
// @extend %button-primary-bg-1;
// @extend %button-primary-bg-10;
// absolute start
%position-v-center {
  @include position-combo(y-center);
}
%position-h-center {
  @include position-combo(x-center);
}
%position-center {
  @include position-combo(center);
}
%position-t-l {
  @include position-combo(tl);
}
%position-t-r {
  @include position-combo(tr);
}
%position-b-l {
  @include position-combo(bl);
}
%position-b-r {
  @include position-combo(br);
}
// absolute end
%shimmer-edge {
  @include shimmer();
}
%shimmer-round {
  @include shimmer(round);
}
%gap-full {
  gap: var(--full-space);
  row-gap: var(--full-space);
  column-gap: var(--full-space);
}
%gap-half {
  gap: var(--half-space);
  row-gap: var(--half-space);
  column-gap: var(--half-space);
}
%gap-quater {
  gap: var(--quater-space);
  row-gap: var(--quater-space);
  column-gap: var(--quater-space);
}
// radius
%full-radius {
  @include border-radius(var(--full-radius));
}
%half-radius {
  @include border-radius(var(--half-radius));
}
%circle-radius {
  @include border-radius(50%, hidden);
}
%rounded-radius {
  @include border-radius(50vmax);
}
%relative {
  position: relative;
}
@each $direction,
$direction-value in $directions {
  @each $space,
  $space-value in $spacing {
    %m-#{$direction}-#{$space} {
      margin#{$direction-value}: #{$space-value};
    }
    %p-#{$direction}-#{$space} {
      padding#{$direction-value}: #{$space-value};
    }
  }
}
//how to use
// @extend %m-t-full
// @extend %p-r-half
// @extend %m-b-full-neg
// @extend %p-l-half-neg
// @extend %m-l-auto
// @extend %m-x-full : margin-inline
// @extend %m-y-full : margin-block
@each $space,
$space-value in $spacing {
  %m-#{$space} {
    margin: #{$space-value};
  }
  %p-#{$space} {
    padding: #{$space-value};
  }
}
//how to use
// @extend %m-full
// @extend %p-half
// @extend %m-full-neg
// @extend %p-half-neg
@for $i from 1 through 10 {
  %h-#{calc($i * 10)} {
    height: calc($i * 10%);
  }
  %w-#{calc($i * 10)} {
    width: calc($i * 10%);
  }
}
// text alignment case
%uppercase {
  text-transform: uppercase;
}
%lowercase {
  text-transform: lowercase;
}
%capitalize {
  text-transform: capitalize;
}
// text alignment
%text-center {
  text-align: center;
}
%text-left {
  text-align: left;
}
%text-right {
  text-align: right;
}
%text-underline {
  text-decoration: underline;
}
%d-none {
  display: none;
}
%d-block {
  display: block;
}
%d-inline-block {
  display: inline-block;
}
@each $justify,
$justify-value in $flex-spacing {
  @each $align,
  $align-value in $flex-spacing {
    %flex#{$justify}#{$align} {
      @include flex-config(flex, null, #{$justify-value}, #{$align-value});
    }
    %flex-column#{$justify}#{$align} {
      @include flex-config(flex, column, #{$justify-value}, #{$align-value});
    }
    %flex-row#{$justify}#{$align} {
      @include flex-config(flex, row, #{$justify-value}, #{$align-value});
    }
  }
}
%flex {
  @include flex-config(flex);
}
%flex-column {
  @include flex-config(flex, column);
}
// shortform
// 	sa: space-around,
// 	sb: space-between,
// 	se: space-evenly,
// 	fe: flex-end,
// 	fs: flex-start,
// 	c:center,
// 	s:stretch,
// 	u:unset,
// 	n:null
//how to use
// @extend %flex;
// @extend %flex-column;
// @extend %flex-null-c;
// @extend %flex-sb-c;
// @extend %flex-c-fs;
// @extend %flex-column-fs-c;
// @extend %flex-column-c-sb;
%flex-wrap {
  flex-wrap: wrap;
}
%x-scroll {
  overflow-x: scroll;
}
%x-auto {
  overflow-x: auto;
}
%y-scroll {
  overflow-y: scroll;
}
%y-auto {
  overflow-y: auto;
}
%truncate-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@each $width,
$height in $aspect-ratio {
  %ratio-#{$width}-#{$height} {
    aspect-ratio: #{$width}/#{$height};
  }
}
//icon extend 
//curently in R&D dont use
@each $icon,
$value in $icons {
  @for $size from 1 through 60 {
    %#{$icon}-#{$size} {
      content: $value;
      @include font($size, $size, $font-icon);
    }
  }
}
//how to use
//%thumb-up-16;
// Gradients
@each $grad-direction,
$grad-direction-value in $gradient-direction {
  @each $grad-colors,
  $grad-colors-value in $gradient-colors {
    %gradient#{$grad-direction}#{$grad-colors} {
      @include linear-gradient($grad-direction-value, $grad-colors-value);
    }
  }
}
//btn-fill
.btn-fill {
  @include btn(none, var(--c-white), var(--c-blue));
  &:hover {
    @include btn(none, var(--c-orange), var(--c-white));
  }
}
//btn-outline
.btn-outline {
  @include btn(0.1rem solid var(--c-white), transparent, var(--c-white));
  &:hover {
    @include btn(0.1rem solid var(--c-orange), transparent, var(--c-orange));
  }
}
.d-none {
  @extend %d-none;
}
%bg-transparent {
  background: transparent;
}
//use for card layout
%card {
  backdrop-filter: blur(0.4rem);
  box-shadow: 0 0.2rem 1rem hsla(var(--hsl-c-pure-black)/0.1);
  @include border-radius(var(--full-radius), hidden);
  @include border(1, c-black, 2, all);
  @extend %c-white-bg-3;
  @extend %p-full;
}
//badges or status
%badges {
  width: max-content;
  height: max-content;
  gap: var(--half-space);
  padding: calc(var(--half-space)/2) var(--half-space);
  @include border-radius(var(--two-space));
  @extend %c-error-bg-6;
  .text {
    @extend %font-16-pr;
    @extend %c-white-10;
  }
}
@each $event-name,
$event-id in $events {
  %event-bg-#{$event-id} {
    @include linear-gradient(180deg,
      var(--event-#{$event-id}-primary) 0%,
      var(--event-#{$event-id}-secondary) 100%);
  }
}
%head-position {
  .waf-head {
    .head-wrap {
      @extend %flex-sb-c;
    }
    .head-tab {
      @include position-combo(x-center, null, var(--full-space-negative));
    }
  }
}
%white-bg {
  .form-control,
  input[type=password],
  input[type=text],
  input[type=email] {
    @include border(1, c-black, 10, bottom);
    @include color(c-blue, 10);
    @include font(16);
    &::placeholder {
      @extend %c-blue-10;
      @extend %font-16-pr;
    }
  }
  select {
    &.form-control {
      @include border(1, c-black, 10, bottom);
      @extend %c-black-10;
    }
  }
  .control-label {
    @extend %c-black-6;
  }
  .checkmark {
    border-color: hsl(var(--hsl-c-black)/0.4);
    @extend %c-black-bg-2;
  }
  .filter-section {
    padding-inline: var(--full-space);
    .filter {
      &-search {
        width: calc(100% - 4.7rem);
      }
      &-toggle {
        border-color: var(--c-blue);
        .btn-text {
          &:after {
            @include color(c-black);
          }
        }
      }
      &-title {
        @extend %c-blue-10;
      }
    }
    .toggle-body {
      @extend %c-black-bg-5;
    }
    input {
      @include border-radius(50vmax);
      @include bg(c-blue, 1);
    }
  }
}
%dash-pagination {
  .swiper {
    &-pagination {
      --swiper-pagination-bottom: 2rem;
      gap: var(--quarter-space);
      @extend %flex-c-n;
    }
    &-pagination-bullet {
      width: 3rem;
      height: 0.3rem;
      transition: width 5s linear;
      @extend %rounded-radius;
      @extend %relative;
      @extend %c-white-bg-4;
      &:hover {
        cursor: pointer;
      }
      &-active {
        background: hsl(var(--hsl-c-white) / .6);
        &::before {
          content: "";
          width: 0;
          display: inline-block;
          animation: slide 5.3s linear;
          @extend %h-100;
          @extend %rounded-radius;
          @extend %position-t-l;
          @extend %c-pure-white-bg-10;
        }
      }
    }
    
  }
  @media (min-width: $desktop-min-width) {
    .swiper-pagination {
      --swiper-pagination-bottom: 10rem;
    }
  }
}