@use '../config/' as *;
.waf-search-head {
  margin-bottom: 6rem;
  @extend %p-x-half;
  .waf-head {
    @extend %m-b-full;
    .head-wrap {
      display: block;
    }
    .title {
      line-height: 8rem;
      @include font(40, 44, $font-sb);
      @extend %uppercase;
      @extend %c-blue-10;
    }
  }
  .head-search {
    line-height: 1;
    @extend %full-radius;
    @extend %m-t-full;
    .search {
      &-info {
        max-height: 0;
        padding: var(--full-space) var(--half-space);
        border-bottom-left-radius: var(--full-radius);
        border-bottom-right-radius: var(--full-radius);
        margin-top: -2rem;
        box-shadow: 0 0 1.5rem 0 rgba(2, 0, 32, 0.15);
        opacity: 0;
        overflow-y: auto;
        transition: max-height .3s ease-in;
        @include custom-scroll();
        @extend %c-light-grey-1-bg-10;
        &.active {
          max-height: 30.5rem;
          opacity: 1;
        }
        &-item {
          padding-block: 1rem;
          @extend %flex-n-c;
          &:hover {
            cursor: pointer;
          }
          &:not(:last-child) {
            border-bottom: .1rem solid hsl(var(--hsl-c-blue-light) / .5);
          }
        }
      }
      &-wrapper {
        height: 4rem;
        padding: var(--half-space) var(--full-space);
        border-radius: 1.6rem;
        z-index: var(--zindex-search-athlete-bar);
        gap: var(--half-space);
        @extend %flex-n-c;
        @extend %relative;
        @extend %c-light-grey-1-bg-10;
        .form-control {
          border: 0;
          background: transparent;
          font-size: 1.4rem;
          @include placeholder(var(--c-blue-2));
          @extend %p-zero;
          @extend %c-blue-2-10;
        }
      }
    }
    .btn-close {
      @extend %flex-n-c;
      &::after {
        content: "\e804";
        display: inline-block;
        color: var(--c-black);
        font: 2.2rem $font-icon;
      }
      .btn-text {
        @extend %font-zero;
      }
    }
    .item {
      &-info {
        flex-wrap: wrap;
        gap: var(--quarter-space) 0;
        @extend %flex;
      }
      &-thumb {
        width: 3rem;
        height: 3rem;
        flex-shrink: 0;
        @extend %m-r-half;
      }
      &-logo {
        font-size: 1.2rem;
        gap: .5rem;
        @extend %flex-n-c;
        @extend %c-blue-1-10;
        .image {
          width: 1.5rem;
          min-width: 1.5rem;
          height: 1.5rem;
          min-height: 1.5rem;
          object-fit: cover;
        }
      }
      &-title {
        font-size: 1.4rem;
        @extend %m-y-zero;
        @extend %c-blue-10;
        &::after {
          content: "|";
          margin-inline: .5rem;
          font-size: 1.6rem;
          @extend %c-grey-10;
        }
      }
    }
    .image {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center;
      @extend %circle-radius;
    }
  }
  .waf-body {
    .title {
      @extend %c-blue-2-10;
      @extend %font-14-pr;
    }
    .no-result-div {
      margin-top: calc(3*var(--three-space));
      @include flex-config(flex, column, center, center);
      .text {
        @extend %p-x-full;
        @extend %p-y-half;
        @extend %flex-c-c;
        @extend %c-white-10;
        @extend %c-blue-bg-10;
        @include border-radius(var(--two-space));
        &:hover {
          @extend %c-orange-bg-10;
        }
      }
      .image {
        width: 10rem;
        height: 10rem;
      }
      .title {
        @extend %m-y-two-space;
        @extend %c-blue-1-10;
        @extend %font-32-pb;
      }
    }
    .search-result-wrapper {
      .search-result {
        &-list {
          gap: var(--full-space);
          @extend %m-y-two-space;
          @extend %flex-column;
        }
        &-item {
          box-shadow: 0 .4rem 1.6rem 0 rgba(78, 78, 78, 0.10);
          @extend %p-x-full;
          @extend %p-y-half;
          @extend %half-radius;
          @extend %flex-n-c;
          .item-thumb {
            width: 6rem;
            height: 6rem;
            @extend %circle-radius;
            .image {
              @extend %w-100;
              @extend %h-100;
            }
          }
        }
        &-action {
          @extend %flex-c-c;
          .text {
            @extend %p-x-full;
            @extend %p-y-half;
            @extend %flex-c-c;
            @extend %c-white-10;
            @extend %c-blue-bg-10;
            @include border-radius(var(--two-space));
            &:hover {
              @extend %c-orange-bg-10;
            }
          }
        }
      }
      .item {
        &-thumb {
          width: 6rem;
          height: 6rem;
          @extend %circle-radius;
          .image {
            @extend %w-100;
            @extend %h-100;
          }
        }
        &-info {
          margin-left: var(--one-n-half-space);
        }
        &-title {
          @extend %m-y-zero;
          @extend %capitalize;
          @extend %c-blue-10;
          @extend %font-14-pb;
        }
        &-content {
          gap: var(--full-space);
          margin-top: .3rem;
          @extend %flex-n-c;
        }
        &-logo {
          @extend %flex-n-c;
          @extend %c-blue-1-10;
          @extend %font-14-pr;
          .image {
            width: 1.5rem;
            height: 1.5rem;
            @extend %m-r-half;
          }
        }
        &-label {
          @extend %relative;
          &::before {
            position: absolute;
            content: '';
            width: .1rem;
            left: 0;
            @extend %h-70;
            @extend %c-blue-1-bg-10;
            @extend %position-v-center;
          }
          .text {
            @extend %p-l-full;
            @extend %c-blue-1-10;
            @extend %font-14-pr;
          }
        }
        &-action {
          @extend %m-l-auto;
          .btn-site {
            width: 2.4rem;
            height: 2.4rem;
            @extend %relative;
            @extend %flex-c-c;
            @extend %circle-radius;
            @extend %c-light-grey-1-bg-10;
            &::before {
              content: '\e801';
              position: absolute;
              font-family: $font-icon;
              font-size: 1.4rem;
              line-height: 1.6rem;
              @extend %c-blue-2-10;
            }
          }
          .text {
            font-size: 0;
          }
        }
      }
    }
  }
}
@media (min-width: $tablet-min-width) {
  .waf-search-head {
    padding-block: 8rem;
    margin-block: 0;
    .waf-head {
      margin-bottom: 0;
      .head-wrap {
        display: flex;
      }
      .title {
        font: 700 6.4rem/8.4rem $font-sb;
      }
    }
    .head-search {
      width: 35.2rem;
      margin-top: 0;
      position: relative;
      display: block;
      .search {
        &-info {
          width: 100%;
          margin-top: 0;
          z-index: var(--zindex-search-athlete-result);
          @include position(2rem, null, null, 0);
        }
      }
    }
  }
}
@media (min-width: $large-desktop-min-width) {
  .athlete-nav {
    width: 100%;
    z-index: var(--zindex-athlete-nav);
    @include position(25rem, null, null, 0);
  }
}