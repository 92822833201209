@keyframes slide {
    100%{
        width: 100%;
    }
}
@keyframes marquee {
    100%{
        transform: translateX(-100%);
        
    }
}
@keyframes loader {
    0% {
        -webkit-transform: rotate(0deg) scale(1);
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }

    50% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
        transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1);
        transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }
}

@keyframes loader-reverse {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(360deg) scale(1);
        transform: translate(-50%, -50%) rotate(360deg) scale(1);
    }

    50% {
        -webkit-transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
        transform: translate(-50%, -50%) rotate(45deg) scale(1.2);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(0deg) scale(1);
        transform: translate(-50%, -50%) rotate(0deg) scale(1);
    }
}

@keyframes placeholderShimmer {
    100% {
        transform: translateX(100%);
    }
}

@-webkit-keyframes placeholderShimmer {
    100% {
        transform: translateX(100%);
    }
}