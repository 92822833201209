@use '../config/' as *;

.historical-listing {
    &.waf-eventlisitng {
        .table {
            &-title {
                margin-block: 0;
                padding-block: var(--full-space);
                border-top: .1rem solid hsl(var(--hsl-c-black)/0.2);
                @extend %c-light-grey-1-bg-2;
            }
            &-row {
                &:nth-child(odd) {
                    @extend %c-light-grey-1-bg-3;
                }
            }
            &-head-title {
                gap: var(--full-space);
                @extend %p-t-full;
                @extend %p-b-two-space;
                @extend %flex-c-c;
                @include flex-config(flex,row-reverse,center,center);
                .text {
                    @extend %c-blue-2-10;
                    @extend %font-24-pb;
                }
                .flag-img {
                    width: 2.6rem;
                    height: 2.6rem;
                    @extend %m-x-zero;
                }
            }
        }
        .table-body {
            .table-row {
                &:after {
                    content: unset;
                }
            }
            .table-data {
                .text {
                    @extend %c-blue-1-10;
                    @extend %font-14-pr;
                }
                &.event {
                    .text {
                        font-size: 1.2rem;
                        @extend %c-white-10;
                    }
                }
                &.style {
                    @extend %flex;
                }
                &.weight,&.rank,&.points,&.style {
                    width: max-content;
                }
                &.series {
                    .text {
                        @extend %c-blue-2-10;
                    }
                    &::after {
                        content: unset;
                    }
                }
                &.points,&.weight,&.rank,&.extra {
                    .text {
                        padding-left: 0.5rem;
                    }
                    &::before {
                        content: "|";
                        width: 1rem;
                        height: 2rem;
                    }
                }
                &.extra {
                    width: auto;
                    position: unset;
                    .status {
                        padding-block: 0;
                        background: transparent;
                        &::before {
                            content: "";
                            width: 1.5rem;
                            height: 1.5rem;
                            flex-shrink: 0;
                            background: url('/static-assets/images/cssimages/svg/uwwpoints.svg') center/ cover no-repeat;
                        }
                    }
                }
            }
        }
        .loadmore {
            @extend %m-y-two-space;
            @extend %flex-c-c;
            .btn-text {
                @extend %capitalize;
                @extend %c-orange-10;
                @extend %font-16-pb;
            }
        }
    }
    .eventlisting-modal {
        -webkit-backdrop-filter: blur(1rem);
        backdrop-filter: blur(1rem);
        @extend %c-white-bg-7;
        @extend %d-none;
        &.active {
            @extend %d-block;
        }
        .modal {
            &-header {
                @extend %p-t-two-space;
                @extend %p-b-full;
                @extend %p-x-full;
                .close {
                    gap: var(--half-space);
                    cursor: pointer;
                    @extend %flex-n-c;
                    @extend %capitalize;
                    @extend %c-blue-10;
                    @extend %font-14-pb;
                    &:before {
                        content: '\e804';
                        font-family: $font-icon;
                        @extend %font-20;
                        @extend %c-blue-10;
                    }
                }
            }
            &-body {
                flex-direction: column;
                max-height: unset;
            }
        }
        .tab {
            &-head {
                border-radius: (var(--half-radius) var(--half-radius) 0 0);
                @extend %p-full;
                @extend %w-100;
                @extend %c-blue-bg-10;
                .title {
                    @extend %uppercase;
                    @extend %c-white-10;
                    @extend %font-22-pb;
                }
                .meta-data {
                    flex-wrap: wrap;
                    column-gap: var(--one-n-half-space);
                    @extend %flex;
                    .country-info {
                        gap: .6rem;
                        @extend %flex-n-c;
                    }
                    .country-flag {
                        width: 1.4rem;
                        height: 1.4rem;
                    }
                    .meta-date,.country-info {
                        @extend %relative;
                        &::after {
                            content: '|';
                            width: .1rem;
                            height: 1rem;
                            @extend %c-white-10;
                            @include position(-.2rem,-1rem,null,null);
                        }
                    }
                }
                .meta {
                    @extend %font-12-pb;
                    @extend %c-white-10;
                    &-label {
                        font-family: $font-pr;
                    }
                }
            }
            &-body {
                border-radius: 0 0 var(--half-radius) var(--half-radius);
                @extend %w-100;
                @extend %c-white-bg-10;
                .tabs {
                    @extend %p-x-full;
                    @extend %m-b-zero;
                    button {
                        @extend %d-none;
                    }
                    .tab-text {
                        @extend %c-blue-1-10;
                        @extend %font-14-pr;
                    }
                    .tab-name  {
                        border-bottom: 0;
                        @extend %flex-c-c;
                        &.active {
                            border-bottom: .2rem solid hsl(var(--hsl-c-orange)/1);
                            .tab-text {
                                font-family: $font-pb;
                                @extend %c-blue-10;
                            }
                        }
                    }
                }
                .tab-container {
                    height: calc(100vh - 21.5rem);
                    overflow-y: auto;
                    @include custom-scroll;
                    .tab-item-podium {
                        // display: none;
                        .table {
                            &-left {
                                width: 20%;
                            }
                            &-right {
                                width: 80%;
                            }
                            &-body {
                                .table-row {
                                    &:nth-child(even) {
                                        .table-data {
                                            @extend %c-light-grey-1-bg-2;
                                        }
                                    }
                                }
                            }
                            &-data {
                                flex-shrink: 0;
                                height: 100%;
                                align-items: center;
                                border-right: .1rem solid hsl(var(--hsl-c-blue)/0.2);
                                .text {
                                    @extend %c-blue-1-10;
                                    @extend %font-12-pr;
                                }
                            }
                        }
                        .table-right {
                            .table-data {
                                width: 60%;
                            }
                        }
                    }
                    .tab-item-rank {
                        // display: none;
                        .table {
                            &-right {
                                .table-data {
                                    justify-content: center;
                                }
                                .table-body {
                                    .table-data {
                                        align-items: center
                                    }
                                }
                            }
                            &-left {
                                .table-row {
                                    flex-wrap: nowrap;
                                }
                                .rank {
                                    width: 6rem;
                                    &::before {
                                        content: unset;
                                    }
                                }
                                .country {
                                    width: calc(100% - 6rem);
                                    .table-data-wrap {
                                        align-items: center
                                    }
                                    .text {
                                        font-family: $font-pr;
                                        line-height: 1.8rem;
                                        @extend %text-left;
                                        @extend %c-blue-1-10;
                                    }
                                }
                            }
                            &-body {
                                .table-row {
                                    &:nth-child(odd) {
                                        .table-data {
                                            @extend %c-light-grey-1-bg-2;
                                        }
                                    }
                                }
                            }
                            &-head {
                                .table-data {
                                    position: relative;
                                    &::before {
                                        content: '';
                                        left: 0;
                                        width: .1rem;
                                        height: 50%;
                                        left: 0;
                                        @extend %c-blue-bg-2;
                                        @extend %position-v-center;
                                    }
                                    &.rank {
                                        &::before {
                                            content: unset;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .table {
                        @extend %flex;
                        border-bottom: .1rem solid hsl(var(--hsl-c-blue)/0.2);
                        &-head {
                            @extend %c-light-grey-1-bg-10;
                            display: block;
                            .table-row {
                                @extend %p-zero;
                            }
                            .table-data {
                                @extend %c-light-grey-1-bg-10;
                            }
                        }
                        &-left {
                            width: 50%;
                            .table-body {
                                .text {
                                    font-family: $font-pb;
                                    @extend %c-blue-10;
                                }
                            }
                        }
                        &-right {
                            width: 50%;
                            .table-row {
                                flex-wrap: nowrap;
                                flex-direction: column;
                                justify-content: center;
                            }
                            .table-head {
                                .table-row {
                                    justify-content: flex-start;
                                    flex-direction: row;
                                }
                                .table-data {
                                    align-items: center;
                                }
                            }
                            .table-body {
                                .table-row {
                                    justify-content: flex-start;
                                    flex-direction: row;
                                }
                                .table-data {
                                    gap: 0.5rem;
                                    flex-direction: column;
                                    justify-content: center;
                                }
                            }
                            .table-data {
                                width: 8rem;
                                flex-shrink: 0;
                                align-items: flex-start;
                            }
                        }
                        &-body {
                            .table-row {
                                height: 6rem;
                                @extend %p-zero;
                            }
                        }
                        &-head,&-row {
                            @include bg(transparent);
                        }
                        &-row {
                            border-top: 0;
                            border-bottom: 0;
                        }
                        &-data {
                            height: 100%;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .historical-listing {
        &.waf-eventlisitng {
            .table {
                &-head-title {
                    border-bottom: .1rem solid hsl(var(--hsl-c-black)/0.2);
                }
                &-title {
                    border-top: 0;
                    @include bg(transparent);
                }
                &-responsive {
                    padding-block: var(--full-space);
                    padding-inline: var(--two-space);
                    margin-bottom: var(--two-space);
                    border-radius: var(--half-radius);
                    border: .1rem solid var(--c-light-grey-1);
                    @include bg(c-light-grey-1,2);
                }
                &-head {
                    .table-row {
                        @include bg(transparent);
                        &:hover {
                            box-shadow: none;
                        }
                    }
                }
            }
            .table-body {
                .table-data {
                    &.points,&.weight,&.rank,&.extra {
                        &::before {
                            content: unset;
                        }
                    }
                    &.extra {
                        .text {
                            display: none;
                        }
                        .status {
                            &::before {
                                width: 2.2rem;
                                height: 2.2rem;
                            }
                        }
                    }
                }
            }
        }
        .eventlisting-modal {
            .modal-dialog {
                width: 80%;
                margin-inline: auto;
            }
            .table-row {
                &:hover {
                    box-shadow: none;
                }
            }
            .tab {
                &-head {
                    padding-inline: var(--two-space);
                    padding-top: var(--one-n-half-space);
                    .title {
                        font-size: 4.2rem;
                    }
                }
                &-body {
                    .tabs {
                        height: 5rem;
                        margin-top: 0;
                        padding-inline: var(--three-space);
                    }
                    .tab-container {
                        height: calc(100vh - 33.5rem);
                        .table {
                            border-bottom: 0;
                        }
                        .table-head {
                            .table-row {
                                height: 5.5rem;
                            }
                            .table-data {
                                @include bg(transparent);
                            }
                        }
                        .tab-item-podium {
                            .table-head {
                                .table-data {
                                    &:before {
                                        content: "";
                                        width: 0.2rem;
                                        height: 2rem;
                                        @include bg(c-blue,2);
                                        @include position(null,null,null,0);
                                    }
                                }
                            }
                            .table-body {
                                .table-row {
                                    height: 7rem;
                                    &:nth-child(even) {
                                        .table-data {
                                            @include bg(transparent);
                                        }
                                    }
                                    &:nth-child(odd) {
                                        .table-data {
                                            @include bg(c-light-grey-1,3);
                                        }
                                    }
                                }
                            }
                            .table-data {
                                padding-inline: var(--full-space);
                                border-right: 0;
                                border-top: .1rem solid hsl(var(--hsl-c-blue)/0.2);
                                .text {
                                    font-size: 1.4rem;
                                }
                            }
                            .table-left {
                                width: 15%;
                                .table-head {
                                    .table-data {
                                        &:before {
                                            content: unset;
                                        }
                                    }
                                }
                            }
                            .table-right {
                                width: 85%;
                            }
                        }
                        .tab-item-rank {
                            .table-left {
                                .rank,.country {
                                    flex: unset;                                    
                                }
                                .rank {
                                    width: 9rem;
                                }
                                .country {
                                    width: calc(100% - 9rem);
                                }
                            }
                            .table-data {
                                border-top: .1rem solid hsl(var(--hsl-c-blue)/0.2);
                                padding-inline: var(--full-space);
                            }
                            .table-body {
                                .table-row {
                                    &:nth-child(odd) {
                                        .table-data {
                                            @include bg(c-light-grey-1,3);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}