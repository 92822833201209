@use "../config/" as *;


.modal{
    position: fixed;
    inset: 0;
    z-index: var(--zindex-modal);
    @extend %c-blue-bg-10;
    &-head{
        @extend %p-full;
        .btn-text{
            position: relative;
            @extend %c-white-10;
            @extend %font-zero;
            &::after{
                @include icon('\e804');
                @include font(24);
                @extend %c-white-10;
            }
        }
    }
    &-body{
        max-height: calc(var(--window-inner-height) - 17rem);
        @extend %flex-c-c;
        @extend %h-100;
    }
    .social {
        &-share {
            width: 3rem;
            height: 3rem;
            aspect-ratio: 1/1;
            @include position(var(--full-space), var(--full-space));
        }
    }
    .share {
        &-icon {
            width: 3rem;
            height: 3rem;
            aspect-ratio: 1/1;
        }
        &-label {
            @extend %d-none;
        }
    }
    .icon-b-share {
        &::before {
            @include icon('\e816');
            @include font(24);
        }
    }
}
.photo-modal{
    .article{
        &-list{
        flex-direction: unset;
        margin-top: 0;
    }
    &-thumbnail{
        border-radius: unset;
        border-color: transparent;
    }
}
    .swiper{
        &-button{
            display: block;
            &::after{
                font: 3.2rem/0 $font-icon; 
                @extend %c-white-10;
            }
        }
    }

}

@media (min-width:$tablet-min-width) {
    .modal{
        padding: var(--two-space) calc(2 * var(--three-space));
        @include flex-config(flex,column,space-between);
        &-head{
            padding: 0;
            .btn-text{
                font: 1.4rem/4rem $font-pm;
                &::after{
                    @include position-combo(y-center,null,-2.2rem);
                }
            }
        }
        &-footer{
            @include flex-config(flex,null,flex-end);
        }
        .social{
            &-share{
                position: relative;
            }
        }
    }
    .photo-modal{
        .preview-swiper{
            max-height: 100%;
        }
        .swiper{
            &-button{
                top:50%;
            }
        }
    }
}