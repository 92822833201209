@use "./map" as *;
:root {
  @each $color,
  $value in $colors {
    --#{$color}: hsl(#{$value});
    --hsl-#{$color}: #{$value};
  }
  @each $color,
  $color-value in $colors {
    @each $event-name,
    $event-id in $events {
      --event-#{$event-id}-primary:hsl(var(--hsl-c-#{$event-id}-primary));
      --event-#{$event-id}-secondary:hsl(var(--hsl-c-#{$event-id}-secondary));
    }
  }
  // non color variables
  --base-font-size: 62.5%;
  --container-max-width: 100%;
  --content-width: var(--window-inner-width);
  --vh: 1vh;
  --vw: 1vw;
  --window-inner-height: calc(var(--vh, 1vh) * 100);
  --window-inner-width: calc(var(--vw, 1vw) * 100);
  // space variables
  --full-space: 1.6rem;
  --full-space-negative: calc(var(--full-space) * -1);
  --one-n-half-space: calc(var(--full-space) + var(--half-space));
  --half-space: calc(var(--full-space) / 2);
  --half-space-negative: calc(var(--half-space) * -1);
  --quarter-space: calc(var(--half-space) / 2);
  --quarter-space-negative: calc(var(--quarter-space) * -1);
  --one-third-space: calc(var(--full-space) / 3);
  --three-fourth-space: calc(var(--full-space) - var(--quarter-space));
  --two-space: calc(2 * var(--full-space));
  --three-space: calc(3 * var(--full-space));
  --full-radius: 1.6rem;
  --half-radius: calc(var(--full-radius) / 2);
  --container-white-space: 8rem;
  // calc(
  //   (var(--window-inner-width) - var(--container-max-width)) / 2
  // );
  // height varible
  --header-height: 6rem;
  --logo-ratio: 0.57142857142;
  --logo-height: 4rem;
  --logo-width: calc(var(--logo-height) / var(--logo-ratio));
  --tabs-height:7rem;
  --search-height:4.5rem;
  --eventstrip:3.3rem;
  --inside-page-breadcrumb-height: 4.8rem;
  --zindex0:0;
  --zindex1:1;
  --zindex-pattern:-1;
  --zindex-header:100;
  --zindex-inside-nav: 96;
  --zindex-inside-breadcrumb: 97;
  --zindex-eventstrip:100;
  --overlay-image:2;
  --zindex-filter:40;
  --zindex-star:3;
  --zindex-filter-search: 5;
  --zindex-search:98;
  --zindex-search-loader:99;
  --zindex-overlay:-1;
  --zindex-tab:4;
  --zindex-input:5;
  --zindex-login:101;
  --zindex-modal:101;
  --zindex-vs: -1;
  --zindex-dropdown:2;
  --zindex-subscribe-modal: -2;
  --zindex-content:1;
  --zindex-sticky-filter:1;
  --zindex-header-player-results: 1;
  --zindex-loader: 7;
  --zindex-search-athlete-bar: 4;
  --zindex-search-athlete-result: 3;
  --zindex-select-year: 1;
  --zindexnegative1:-1;
  --zindexoverlayads: 41;
  --zindexhalloffamecontent: -1;
  --zindexmodalclose: 2;
  --zindexheadtoheadsearch: 1;
  --zindex-head-to-head-bg: -1;
  --zindex-athlete-nav: 2;
  --zindex-featured-list-gradient: 2;
  //swiper
  --swiper-button-width:2.4rem;
}
$small-mobile-min-width: 0px;
$small-mobile-max-width: 360px;
$medium-mobile-min-width: 576px;
$mobile-max-width: 767px;
$tablet-min-width: 768px;
$tablet-max-width: 991px;
$desktop-min-width: 992px;
$large-desktop-min-width: 1200px;
$large-desktop-max-width: 1199px;
$xl-desktop-min-width: 1600px;
$default-line-height: 1.6;
$font-pr: "OpenSans-Regular", sans-serif;
$font-pm: "OpenSans-Medium", sans-serif;
$font-psb: "OpenSans-SemiBold", sans-serif;
$font-pb: "OpenSans-Bold", sans-serif;
$font-sb: "Fedra-SansConds-Bold", sans-serif;
$font-icon: "waf-font-icon";
$image-version: "4.29";
$font-version: "95656688";
@media (min-width: $tablet-min-width) {
  :root {
    --container-max-width: calc(100% - var(--container-white-space));
    --header-height: 6.5rem;
    --header-top-height: 4rem;
    --header-bottom-height: 6rem;
    --shape-header-height: 8.8rem;
    --inside-page-breadcrumb-height: 14.9rem;
  }
}
@media (min-width: $desktop-min-width) {
  :root {
    --container-max-width: calc(100% - 8rem);
    --showcase-height: calc(var(--window-inner-height) - var(--header-height));
  }
}
@media (min-width: $large-desktop-min-width) {
  :root {
    --container-max-width: calc(100% - var(--container-white-space));
    --header-height: 6.5rem;
    --header-bottom-height: 8rem;
    --logo-height: 3.6rem;
  }
}
@media (min-width: $xl-desktop-min-width) {
  :root {
    --container-max-width: calc(100% - var(--container-white-space));
  }
}