@use "../config/" as *;
.waf-eventlisitng {
    margin: 0 var(--half-space-negative);
    @extend %p-zero;
    @extend %white-bg;
    .waf-head {
        margin-block: var(--two-space) var(--full-space);
        @extend %p-x-full;
        .title {
            @extend %font-42-sb;
            @extend %uppercase;
        }
    }
    .head-tab {
        @extend %d-none;
    }
    .table {
        border: none;
        &-row {
            position: relative;
            flex-wrap: wrap;
            border-bottom: 0.1rem solid var(--c-light-grey-1);
            @extend %p-full;
            @extend %flex-n-c;
            @extend %c-blue-10;
            &:nth-child(odd) {
                background-color: hsl(var(--hsl-c-light-grey-1)/0.3);
            }
            &:first-child {
                border-top: 0.1rem solid hsl(var(--hsl-c-black)/0.2);
            }
            @each $event-name,
            $event-id in $events {
                &.#{$event-name} {
                    .event {
                        .text {
                            @extend %event-bg-#{$event-id};
                        }
                    }
                    .logo {
                        background:url(/static-assets/images/cssimages/svg/events/#{$event-name}.svg?v=#{$image-version}) no-repeat center center;
                    }
                    &.highlight {
                        background: hsl(var(--hsl-c-#{$event-id}-primary)/0.3);
                        border: 0.1rem solid hsl(var(--hsl-c-#{$event-id}-primary)/1);
                        @include border-radius(var(--half-radius));
                    }
                }
            }
            &.olympic-qualifying-tournament, &.olympic-games {
                .event .text {
                    @extend %c-blue-2-10;
                }
            }
        }
        &-title {
            @extend %p-x-full;
            @extend %font-18-pb;
        }
        &-head {
            @extend %d-none;
        }
        &-data {
            height: unset;
            @extend %flex;
            &.series {
                @extend %d-block;
                @extend %text-left;
            }
            &.style {
                @extend %d-none;
            }
        }
        &-data-wrap {
            display: flex;
        }
        &-body {
            .table-row {
                .event .text {
                    @extend %badges;
                    @extend %c-blue-2-bg-10;
                    @extend %c-white-10;
                }
                &::after {
                    content: unset;
                    width: 1.5rem;
                    height: 1.5rem;
                    font-weight: bold;
                    position: absolute;
                    font-family: $font-icon;
                    right: var(--one-n-half-space);
                    @extend %position-v-center;
                    @extend %circle-radius;
                    @extend %flex-c-c;
                    @extend %c-blue-2-10;
                    @extend %c-light-grey-1-bg-10;
                    @include font(12, 10);
                }
                &.no-link {
                    &::after {
                        content: unset;
                    }
                }
            }
        }
        &-wrapper {
            &:not(:first-child) {
                .table-title {
                    @extend %m-t-three-space;
                }
            }
        }
    }
    .event {
        order: -2;
        width: max-content;
        @extend %m-b-half;
    }
    .series {
        order: -1;
        @extend %w-100;
        @extend %m-b-half;
        .text {
            @extend %font-18-pr;
            @extend %c-blue-10;
        }
        &::after {
            content: ' - ' attr(data-style) ' ';
            position: relative;
            @extend %c-blue-10;
            @extend %font-18-pr;
        }
    }
    .extra {
        width: 8rem;
        @include position(var(--full-space), var(--full-space));
        @extend %flex-fe-fe;
        .status {
            padding: 0.6rem;
            gap: var(--quarter-space);
            @extend %flex-n-c;
            @extend %c-error-bg-10;
            @extend %badges;
            &::before {
                content: '';
                width: 0.9rem;
                height: 0.9rem;
                aspect-ratio: 1/1;
                flex-shrink: 0;
                background-image: url('/static-assets/images/svg/live-white.svg?v=#{$image-version}');
            }
            .text {
                font-size: 1.2rem;
                line-height: var(--half-space);
                @extend %capitalize;
            }
        }
        .logo {
            width: 3.6rem;
            height: 3.6rem;
            @extend %circle-radius;
        }
        .btn-schedule {
            font-size: 1.2rem;
            line-height: 1.4rem;
        }
    }
    .place,
    .category {
        width: max-content;
        @extend %c-blue-6;
        &:before {
            content: '|';
            width: 1rem;
            height: 2rem;
        }
        .text {
            color: var(--event-institutional-primary);
            @extend %font-14-pr;
        }
    }
    .category {
        .text {
            padding-left: .5rem;
        }
    }
    .date {
        width: max-content;
    }
    .flag-img {
        width: 2rem;
        height: 2rem;
        display: flex;
        border-radius: 50%;
        margin-inline: 0.5rem var(--half-space);
        flex-shrink: 0;
        @extend %c-orange-bg-10; //temp
    }
    .swiper {
        &-button {
            @extend %d-none;
            &::after {
                font-size: 3rem;
                @extend %c-white-10;
            }
            &-prev {
                left: var(--one-n-half-space);
            }
            &-next {
                right: var(--one-n-half-space);
            }
        }
        &-scrollbar {
            @extend %d-none;
        }
    }
    .filter-section {
        @extend %m-b-full;
        .filter-result-data {
            @extend %m-y-three-space;
        }
        .filter-result-list {
            flex-wrap: wrap;
            @extend %m-y-zero;
        }
        .filter-title {
            @extend %d-none;
        }
        .filter-toggle {
            &[aria-expanded="false"] {
                ~ .filter-body {
                    .filter-label-group {
                        @extend %d-none;
                    }
                }
            }
        }
        .selected-label {
            border-color: hsl(var(--hsl-c-white)/0.3);
            color: var(--c-white);
        }
        .sub-title {
            color: var(--c-white);
        }
        .toggle-text {
            @extend %c-white-10;
            &::before {
                color: var(--c-white);
            }
        }
        .list-item-text {
            color: var(--c-blue);
        }
        input[type=text] {
            border-bottom: none;
            @include bg(c-light-grey-1, 10);
        }
        input::placeholder {
            @include color(c-blue-1, 10);
        }
        .filter-count {
            line-height: 1.5;
            @extend %m-b-full;
            @extend %d-inline-block;
            @extend %c-black-6;
            @extend %font-12;
        }
    }
    .place {
        &.more-candidates {
            order: 1;
            align-items: flex-start;
            @extend %w-100;
            @extend %m-t-half;
            &::before {
                content: unset;
            }
            .table-data-list {
                flex-wrap: wrap;
                gap: var(--half-space);
                @extend %flex;
            }
            .table-data-title {
                @extend %p-r-half;
                @extend %relative;
                @extend %font-14-pr;
                @extend %c-blue-2-10;
                @extend %capitalize;
                &:after {
                    content: ':';
                    @include position(null,0,null,null);
                }
            }
        }
    }
}
.event-row {
    overflow: hidden;
}
.event-hide {
    .filter-section {
        .filter {
            &-group {
                @extend %d-none;
            }
            &-hide-data {
                @extend %d-none;
            }
        }
    }
}
@media (min-width:$tablet-min-width) {
    .waf-eventlisitng {
        @include white-bg();
        .swiper {
            &-button {
                display: block;
            }
        }
        .filter-section {
            margin-bottom: var(--three-space);
            position: relative;
            padding-inline: 0;
            .filter-body {
                width: calc(100% - 20rem);
            }
            .waf-select-box {
                margin-bottom: 0;
            }
            .selected-label {
                border-color: hsl(var(--hsl-c-blue)/0.3);
                @include color(c-blue);
                &::after {
                    @include color(c-black);
                }
            }
            .list-item-option {
                .list-item-text {
                    @include color(c-blue);
                }
            }
            .toggle-text {
                &::before {
                    color: var(--c-blue);
                }
            }
            .filter-result-data {
                margin-block: var(--full-space) 0;
            }
        }
        .table {
            &-body {
                .table-row {
                    &::after {
                        content: '\e801';
                    }
                }
            }
        }
    }
    .table {
        &-head {
            background: transparent;
            height: auto;
        }
        &-body {
            @include grid(repeat(2, 1fr));
            .place {
                &.more-candidates {
                    order: unset;
                    @include flex-config(flex,column,null,flex-start);
                    .table-data-title {
                        padding-bottom: var(--half-space);
                    }
                    .table-data-list {
                        gap: .6rem;
                        @include flex-config(flex,column,null,null);
                    }
                }
            }
        }
    }
    .extra {
        .btn-schedule {
            margin-right: var(--half-space);
        }
    }
    .event-hide {
        .filter-section {
            .filter {
                &-group {
                    display: flex;
                }
            }
        }
    }
    // .category {
    //     .text {
    //         padding-left: 0;
    //     }
    // }
}
@media (min-width:$desktop-min-width) {
    .waf-eventlisitng {
        .waf-head {
            padding: 0;
            .title {
                font-size: 6.4rem;
            }
        }
        .text {
            font: 1.4rem $font-pr;
            text-align: left;
            @include color(c-blue);
        }
        .table {
            &-head {
                display: flex;
                .text {
                    @include font(14);
                    @include color(c-blue, 8);
                }
                .event {
                    margin: 0;
                    .text {
                        font-size: 1.4rem;
                        @include color(c-blue, 8);
                    }
                }
                .table-data {
                    gap: 0.3rem;
                    position: relative;
                    &:not(:first-child) {
                        &:before {
                            content: '';
                            width: 0.2rem;
                            height: 2rem;
                            position: absolute;
                            left: var(--full-space-negative);
                            @include bg(c-blue, 2);
                        }
                    }
                    &.series {
                        display: flex;
                    }
                }
                .table-row {
                    background: transparent;
                    border-top: none;
                }
            }
            &-data {
                flex: 1;
                // max-width: 20rem;
                padding-left: 0;
                &.style {
                    display: flex;
                }
            }
            &-row {
                width: 100%;
                padding-block: calc(var(--full-space) + var(--quarter-space));
                &:first-child {
                    border-top: none;
                }
                &:hover {
                    box-shadow: 0 0.4rem 1.6rem 0 hsla(var(--hsl-c-black)/0.1);
                }
            }
            &-body {
                display: inherit;
                .series {
                    .text {
                        font-size: 1.6rem;
                        @include color(c-orange, 10);
                    }
                }
                .place,
                .category,
                .style {
                    .text {
                        @include color(c-blue, 6);
                    }
                }
                .table-row::after {
                    right: var(--three-fourth-space);
                }
            }
            &-wrapper {
                margin-bottom: var(--two-space);
            }
            &-title {
                @include font(20);
                @include color(c-blue);
            }
        }
        .date {
            max-width: 11rem;
            .text {
                @include color(c-blue, 8);
            }
        }
        .extra {
            max-width: 9rem;
        }
        .series,
        .event {
            order: unset;
        }
        .series {
            min-width: 28rem;
            &::after {
                content: unset;
            }
            .text {
                font-size: 1.4rem;
            }
        }
        .category {
            word-break: break-all;
        }
        .event {
            min-width: 20rem;
            margin-bottom: 0;
            padding: 0;
            .text {
                font-size: 1.2rem;
                @include color(c-white);
            }
        }
        .extra {
            position: relative;
            top: unset;
            right: unset;
            align-items: center;
            justify-content: flex-start;
        }
        .place,
        .category {
            &::before {
                content: unset;
            }
        }
        .category {
            .text {
                padding-left: 0;
            }
        }
    }
    .filter-sticky {
        .waf-eventlisitng {
            .filter-section {
                z-index: var(--zindex-sticky-filter);
                @include bg(c-white);
                @include position(var(--header-height), var(--container-white-space), null, var(--container-white-space), sticky);
                .filter-wrap {
                    padding-top: var(--half-space);
                }
            }
        }
    }
}